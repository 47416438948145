<template>
  <div class="p-grid card">
    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Pontos de Apoio</h5>
      </template>

      <template #right>
        <span class="p-d-inline-flex p-mr-2">
          <p-inputtext
            v-model="search.q"
            @keypress.enter="consultar"
            placeholder="Pesquisar..."
          />
          <p-button icon="pi pi-search" @click="consultar" />
        </span>
      </template>
    </p-toolbar>

    <p-datatable
      class="p-col-12"
      :value="data"
      :paginator="true"
      :rows="search.first"
      :lazy="true"
      :totalRecords="paginatorInfo.total"
      :loading="loading"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 200, 500]"
      currentPageReportTemplate="{first} - {last} de {totalRecords}"
      stripedRows
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">
          Nenhum registro encontrado!
        </div>
      </template>

      <p-column field="e.nome" header="Nome/Identificação" :sortable="true">
        <template #body="{ data }">
          <a
            style="cursor: pointer; font-weight: bold"
            @click="paView.show(data.id)"
            >{{ data.apelido || data.nome }}</a
          >
        </template>
      </p-column>
      <p-column field="e.telefone" header="Telefone" :sortable="true">
        <template #body="{ data }">
          <a :href="`tel:${data.telefone}`">{{ data.telefone }}</a>
        </template>
      </p-column>
      <p-column field="e.telefone" header="Whatsapp" :sortable="true">
        <template #body="{ data }">
          <a
            href="#"
            @click.prevent="data?.whatsapp && openWhatsapp(data.whatsapp)"
            >{{ data.whatsapp }}</a
          >
        </template>
      </p-column>
      <p-column field="e.cidade" header="Cidade" :sortable="true">
        <template #body="{ data }">
          {{ data.cidade }}
        </template>
      </p-column>
      <p-column field="e.uf" header="UF" :sortable="true">
        <template #body="{ data }">
          {{ data.uf }}
        </template>
      </p-column>
      <p-column bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <p-button
            label="Comprar"
            @click="changePa(slotProps.data.id)"
            class="p-mr-1 p-mt-1"
          />
        </template>
      </p-column>
    </p-datatable>
  </div>

  <x-ponto-apoio-view
    v-model:visible="paView.visible"
    :title="paView.title"
    :id="paView.id"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { PontoApoioService, PontoApoio } from "@/services/PontoApoioService";
import { useConsulta } from "@/composables/crud";
import { formatDate } from "@/utils/format";
import { useShowPontoApoio } from "@/components/PontoApoioView.vue";
import { openWhatsapp } from "@/utils/whats";
import { getService } from "@/services/UserService";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const paView = useShowPontoApoio();
    const { data, loading, search, paginatorInfo, consultar } =
      useConsulta<PontoApoio>(new PontoApoioService());

    search.sortField = "e.cidade";
    search.sortOrder = "ASC";

    onMounted(() => consultar());

    return {
      openWhatsapp,
      paView,
      formatDate,
      data,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },
      async changePa(id: number | null) {
        try {
          await getService().changePontoApoioCompra(id);
          router.push({ name: "loja" });
        } finally {
        }
      },
    };
  },
});
</script>
