
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { PontoApoioService, PontoApoio } from "@/services/PontoApoioService";
import { useConsulta } from "@/composables/crud";
import { formatDate } from "@/utils/format";
import { useShowPontoApoio } from "@/components/PontoApoioView.vue";
import { openWhatsapp } from "@/utils/whats";
import { getService } from "@/services/UserService";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const paView = useShowPontoApoio();
    const { data, loading, search, paginatorInfo, consultar } =
      useConsulta<PontoApoio>(new PontoApoioService());

    search.sortField = "e.cidade";
    search.sortOrder = "ASC";

    onMounted(() => consultar());

    return {
      openWhatsapp,
      paView,
      formatDate,
      data,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },
      async changePa(id: number | null) {
        try {
          await getService().changePontoApoioCompra(id);
          router.push({ name: "loja" });
        } finally {
        }
      },
    };
  },
});
